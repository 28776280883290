@import '../../../Colors.scss';

.text_fade_in {
    animation-name: textfadein;
}

.elem_fade_in {
    animation-name: elemfadein;
    opacity: 0;
}

@keyframes textfadein {
    from {
        color: rgba(255, 255, 255, 0);
    }

    to {
        color: rgba(255, 255, 255, 1);
    }
}

@keyframes elemfadein {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}