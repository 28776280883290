@import '../../../Colors.scss';

.project_video_container {
  height: 100%;
  width: 100%;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: map-get($color, video-container);
  background-color: map-get($bg-color, outer);
}

.project_video_media {
  margin-block-start: 1.5em;
  height: 100%;
  width: 100%;
}
