@import '../../../../Colors.scss';

.projects_display_container {
  width: 100%;
  display: grid;
  padding-inline-start: 1em;
  margin-block-end: 1em;

  box-sizing: content-box;

  background-color: map-get($bg-color, outer);
  grid-template-columns: repeat(1, 1fr);
}
