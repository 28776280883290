@import '../../../Colors.scss';

.submit-button {
    margin-inline-start: 1em;
    border: 1px solid map-get($bg-color, search-bar);
    transition: color ease-in 20ms;
    transition: background-color ease-in 20ms;
    width: 2em;
    height: 2em;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.submit-button:hover {
    color: map-get($color, search-bar-button);
    background-color: map-get($bg-color, search-bar-button);
}