@import '../../../../Colors.scss';

.searchbar_container {
    width: 100%;

    display: flex;

    color: map-get($color, search-bar);
    background-color: map-get($bg-color, search-bar);
    margin-block-end: 2em;
}

@media screen and (min-width: 1024px) {
    .searchbar_container {
        width: 50%;
    }
}

