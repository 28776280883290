@import '../../../../../../Colors.scss';

.presentation_anchor-to-project {
    flex: 1;
    flex-direction: column;
    margin: auto;
    margin-block: 1em;
    font-size: 2em;
    background-color: map-get($bg-color, presentation-hover);
}

.presentation_anchor_button {
    flex: 1;
    fill: currentColor;
    cursor: pointer;
}

@media screen and (max-width: 425px) {
    .presentation_anchor_specialization {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    .presentation_anchor_specialization > * {
        margin-inline-start: 1.5em;
    }

}
