@import '../../../../Colors.scss';

.projects_page_main_content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  flex-grow: 1;
  width: 100%;
  height: 100%;
  background-color: map-get($bg-color, outer);
}