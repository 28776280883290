.presentation_header_container {
    display: flex;
    flex-direction: column;
    width: 100%;
}
  
  /* Tablet styles */
  @media (min-width: 426px) and (max-width: 1024px) {
    /* Styles for tablets */
  }
  
  /* Laptop styles */
  @media (min-width: 1025px) and (max-width: 1440px) {
    .presentation_header_container {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
    }
    /* Styles for laptops */
  }
  
  /* Desktop styles */
  @media (min-width: 1441px) {
    /* Styles for desktops */
  }
