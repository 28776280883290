@import '../../../../../Colors.scss';

.presentation_container {

    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    width: 100%;
    color: map-get($color, presentation);
    background-color: map-get($bg-color, card-content);
}
