@import '../../../../Colors.scss';
//glow hover
.social-network_button:hover>svg {
    background-color: #FFFFFF90;
}

.social-network_button_svg {
    background-color: #FFFFFF00;
    transition: background-color 150ms ease;
    display: inline-block;
    width: 1.5em;
    height: 1.5em;
    fill: currentColor;
    font-size: 1.5rem;
    border-radius: 30%;
}

//general class
.social-network_button {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-width: 10em;
    margin-block: 1em;
    font-size: 0.38rem;
    border: none;
    cursor: pointer;
    background-color: inherit;
    color: map-get($color, social-network);
}