@import '../../Colors.scss';

.searchbar_svg_container {
    flex: 1;
    flex-grow: 1;
    flex-shrink: 1;
    max-width: 100%; /* Optionnel si tu veux limiter la taille */
    max-height: 100%;
    color: map-get($color, search-bar);
}
.searchbar_svg {
    fill: currentColor;
}