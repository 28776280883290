@import '../../../../Colors.scss';

.project_card_preview_container {
    margin-inline-end: auto;
    background-color: map-get($bg-color, outer);
    color: map-get($color, card-content);
    max-height: 20vh;
    overflow: auto;
    overflow-wrap: break-word;
}

.project_card_preview_container * {
    box-sizing: border-box;
    /* S'assure que le padding et la bordure sont inclus dans la taille */
    max-width: 100%;
    /* Empêche les blocs internes de dépasser la largeur du conteneur */
    overflow: scroll;
    /* Empêche les débordements */
    word-wrap: break-word;
    /* Coupe les mots trop longs si nécessaire */
    white-space: normal;
    /* Permet les sauts de ligne */

}