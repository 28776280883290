@import '../../../../Colors.scss';

.projects_title {
    flex: 1;
    flex-shrink: 1;
    width: 100%;
    color: map-get($color, projects-title);
    background-color: map-get($bg-color, projects-title);
}

.projects_title_h1 {    
    margin: 0;
    font-size: 1.5em;
}
