@import '../../../../Colors.scss';

.project_card_item_stack_container {
    display: flex;
    align-content: flex-start;

    color: map-get($color, card-content);

    font-size: 1em;

    margin-block: 2em;
    padding: 0;
}

@media screen and (min-width:1200px) {
    .project_card_item_stack_container {
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
    }
}

@media screen and (min-width:800px) and (max-width: 1199px) {
    .project_card_item_stack_container {
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
    }
}

@media screen and (max-width: 799px) {
    .project_card_item_stack_container {
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        align-content: flex-start;
    }
}

.project_card_item_stack_item {
    margin-inline-start: 1em;
}

.project_card_item_stack_title {
    font-size: 2em;
    align-self: center;
}