.presentation_resume_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.presentation_resume_item {
    flex: 1;
    width: 100%;
}