@import '../../../../Colors.scss';

.project_card_item_background-image_title {
    font-size: 2em;
    color: map-get($color, card-content);
}

.project_card_screenshot_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    min-width: 300px;
    min-height: 169px;

    > * {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
}

@media screen and (min-width:1200px) {
    .project_card_screenshot_container {
        max-width: 50vw;
        min-height: 225px;
    }
}

@media screen and (min-width:800px) and (max-width: 1199px) {
    .project_card_screenshot_container {
        max-width: 50vw;
        min-height: 225px;
    }
}

@media screen and (min-width:600px) and (max-width: 799px) {
    .project_card_screenshot_container {
        max-width: 80vw;
        min-height: 169px;
    }
}

@media screen and (max-width: 599px) {
    .project_card_screenshot_container {
        max-width: 80vw;
        min-height: 20vh;
    }
}

@media screen and (max-width: 400px) {
    .project_card_screenshot_container {
        max-width: 50vw;
        min-height: 20vh;
    }
}