@import '../../../../Colors.scss';

.project_page_container {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  color: inherit;
  background-color: map-get($bg-color, outer);
}

.project_video_item_github-link {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 1rem;
  color: map-get($color, video-container);
}

.project_page_preview_text {
  max-width: 80vw;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  min-height: 100%;
  color: white;
}
