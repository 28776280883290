@import '../../../../Colors.scss';


.project_page_header {
  position: sticky;
  top: 0;
  
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;

//  background: linear-gradient(135deg, #1a1a1a 0%, #333333 100%); // Un dégradé entre gris très foncé et un gris moyen
  background-color: #333333;
  background-size: cover;
  background-position: center;

  color: map-get($color, header);
  width: 100vw;
}

.social-network_button_svg {
  background-color: #FFFFFF00;
  transition: background-color 150ms ease;
  color: map-get($color, header);
  width: 1.5em;
  height: 1.5em;
  fill: currentColor;
  font-size: 1.5rem;
  border-radius: 30%;
}

.project_page_stack {
  visibility: hidden;
  display: flex;
  flex-direction: row;
  width: 100vw;
  //  background-color: map-get($bg-color , outer);
  //  background: linear-gradient(135deg, map-get($colors, black) 0%, map-get($colors, middle-purple) 100%);
  //  background: radial-gradient(circle, map-get($colors, pewter-blue) 0%, map-get($colors, shiny-white) 100%); //a voir

  background-size: cover;
  background-position: center;
}

.project_page_stack p {
  color: map-get($color , header);
  cursor: pointer;
  height: 100%;
}

.project_page_social-network_container {
  cursor: pointer;
}


.project_page_navigate_back {
  color: lightblue;
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  fill: currentColor;
  font-size: 1.5rem;
  border-radius: 30%;
}

.project_page_header_title {
  font-size: 2em;
  padding-inline: 1em;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5); // Un effet subtil pour rendre le titre plus lisible
  font-weight: bold; // Accentue le poids pour renforcer la hiérarchie visuelle
}

.project_page_header_menu-item {
  cursor: pointer;
}

@media screen and (min-width: 400px) {
  .project_page_stack {
    visibility: visible;
    justify-content: center;
    flex: 1;
  }
}