@import '../../../../../Colors.scss';

.presentation_description_container {
    margin-inline-start: 2em;
    display: grid;
}

#presentation_header {
    display: flex;
    justify-content: center;
    align-items: center;
}

@media screen and (min-width: 1024px) {
    .presentation_description_container {
        grid-template-columns: repeat(4, 1fr);
    }
}

@media screen and (min-width: 768px) {
    .presentation_description_container {
        grid-template-columns: repeat(3, 1fr);
    }
}

.tech_title {
    background-color: lighten(map-get($bg-color, presentation), 1%);
}

.tech_item {
    margin-block: 2em;
    padding-inline: 1em;
    padding-block-start: 0.3em;
    padding-block-end: 2em;
    color:  map-get($color, presentation);
}

.tech_item_description {
    background-color: darken(map-get($bg-color, presentation), 1%);
}

a {
    color: map-get($color, presentation);
    transition: background-color 150ms ease, color 150ms ease;
}

a:hover {
    background-color: map-get($color, presentation);
    color: map-get($bg-color, presentation);
}