.typewriterjs_blinking_cursor {
    animation: blink 1s step-start infinite;
}

@keyframes blink {
    0% {
        opacity: 0;
    }
    50% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}
