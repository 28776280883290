@import '../../../Colors.scss';

.project_card_grid_container {
    height: 100%;
    width: 100%;

    display: flex;
    flex-direction: column;
    justify-content: space-evenly;

    border-left: 1px solid map-get($color, card-content);
    box-sizing: border-box;

    padding-inline: 2em;
}

.project_card_grid_screenshot_container_wrapper {
    display: flex;
}

@media screen and (max-width: 599px) {
    .project_card_grid_screenshot_container_wrapper {
        flex-direction: column;
    }

    .project_card_grid_container {
        max-width: 80vw;
    }

}